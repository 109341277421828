/* External dependencies */
import { Anchor, Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import { RootState } from '@app/redux/store';
import { getDefaultPath } from '@app/routes/getDefaultPath';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import { formStyle, wrapStyle } from '@shared/ui/form/FormStyles';
import { login, sendVerificationCodeRequest } from '../redux/actions';

export default function VerifyCode() {
  const dispatch = ReactRedux.useDispatch();
  const [verificationCode, setVerificationCode] = React.useState(null);
  const { error, loading, username, client, currentUser } =
    ReactRedux.useSelector((store: RootState) => store.login);

  const { t } = useTranslation();
  const [seconds, setSeconds] = React.useState(59);
  const minutes = '00';
  let interval;

  if (client) {
    navigate(getDefaultPath(currentUser));
  }

  function startTimer(secs: number) {
    setSeconds(secs);

    interval = setInterval(() => {
      if (seconds) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
        interval = undefined;
      }
    }, 1000);
  }

  React.useEffect(() => {
    startTimer(seconds);

    return () => clearInterval(interval);
  }, [seconds]);

  function onSubmit(e) {
    e.preventDefault();

    dispatch(sendVerificationCodeRequest(username, verificationCode!));
  }

  function onGetNewCode() {
    startTimer(59);

    dispatch(login(username));
  }

  const isInvalid = !verificationCode;

  const code = t('code');
  const codePlaceholder = t('codePlaceholder');
  const getAnotherCode = t('getAnotherCode');
  const getNewVerificationCode = t('getNewVerificationCode');

  return (
    <Div {...wrapStyle} style={{ width: 'inherit' }} m={{ t: '3rem' }}>
      <form onSubmit={onSubmit} style={formStyle}>
        <Row m="0">
          <Col
            border={{ b: '1px solid' }}
            textAlign="left"
            borderColor="basic_border_color"
          >
            <Text
              tag="h1"
              textSize="18px"
              textColor="grey"
              textWeight="bold"
              m="1rem"
            >
              <Trans>loginSignInWithSMS</Trans>
            </Text>
          </Col>
        </Row>
        <Row p="1rem" m="0">
          <FormField
            disabled={loading}
            label={code}
            inputValue={verificationCode}
            inputType="number"
            inputName="verificationCode"
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder={codePlaceholder}
            required={true}
          />
          {error && (
            <Col>
              <Text textColor="red">
                <Trans>{error.message}</Trans>
              </Text>
            </Col>
          )}
        </Row>
        <Row
          m="0"
          border={{ t: '1px solid' }}
          borderColor="basic_border_color"
          p="1rem"
        >
          <Col d="flex" justify="flex-start" align="center">
            <Text>
              {seconds === 0 ? (
                <Anchor onClick={onGetNewCode}>
                  <Text textSize="caption">{getNewVerificationCode}</Text>
                </Anchor>
              ) : (
                `${getAnotherCode}
              ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
              )}
            </Text>
          </Col>
          <Col d="flex" justify="flex-end">
            <Button
              typeColor="primary"
              disabled={isInvalid || loading}
              loading={loading}
              style={{ borderColor: 'btn_primary_bg' }}
            >
              <Trans>loginSubmit</Trans>
            </Button>
          </Col>
        </Row>
      </form>
    </Div>
  );
}
