/* External dependencies */
import { Div } from 'atomize';
import { graphql } from 'gatsby';
import React from 'react';

/* Local dependencies */
import VerifyCode from '../../../entities/authentication/ui/VerifyCode';
import Layout from '../../../shared/ui/layout/WebLayout';
import MedcheckLogoWithSmile from '../../../svg/MedcheckLogoWithSmile';

export default function Login() {
  return (
    <Layout>
      <Div
        d="flex"
        align="center"
        flexDir="column"
        justify="center"
        bg="#E5E5E5"
        h="100vh"
      >
        <MedcheckLogoWithSmile height={50} width={500} />
        <VerifyCode />
      </Div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
